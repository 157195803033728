@font-face {
  font-family: "Ageo";
  src: url("./fonts/Ageo.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: Ageo, Helvetica Neue, sans-serif;
  /* line-height: 1.6; */
  background-color: #fff;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Badge */
.badge {
  background: rgba(166, 163, 255, 0.3);
  border-radius: 5.83333px;
  color: #14007E;
  /* display: inline-block; */
  padding: 6px;
  text-align: center;
}

/* Button */
.button {
  background-color: #14007E;
  border: none;
  color: #fff;
  padding: 12px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 4px 2px;
  border-radius: 5px;
  cursor: pointer;
}

.button.icon {
  width: 80px;
  height: 40px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.button.small {
  width: 140px;
  height: 40px;
}

.button.tiny {
  width: 74px;
  height: 35px;
  font-size: 12px;
}

.button.active {
  background-color: #706cff;
}

.button-outline {
  border: 1px solid #979798;
  padding: 10px 20px;
  border-radius: 5px;
}

/* TopNav */
.topnav {
  padding: 20px 170px;
  overflow: hidden;
  background-color: #14007E;
  width: 100%;
}

.topnav .nav-links {
  float: right;
  /* display: inline; */
  /* -ms-transform: translateY(50%);
  transform: translateY(50%); */
  margin-top: 1%;
}

.topnav.nav-brand {
  width: 120px;
}

.topnav .nav-links a.main-nav {
  /* float: right; */
  /* display: block; */
  color: #ffffff;
  text-align: center;
  /* padding: 14px 16px; */

  margin-left: 30px;
  text-decoration: none;
  font-size: 17px;
}

.topnav img {
  float: left;
}

.topnav input {
  float: right;
  padding: 10px;
  width: 400px;
  background-color: #14007E;
  border: none;
  border-radius: 5px;
  background-image: url("./icons/search.svg");
  background-repeat: no-repeat;
  background-position: 6px 6px;
  color: #ffffff;
}

.topnav .chain-info-container {
  margin-top: 30px;
  display: none;
}

.topnav .nav-icon {
  display: none;
}

.nav-dropbtn {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background-color: inherit;
  font-family: inherit;
  color: #fff;
  font-size: 17px;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  background-color: #14007E;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.list-container {
  height: auto;
}

/* Text utils */
.text-accent-purple {
  color: #14007E;
}

.text-dark-white {
  color: #979798;
}

.text-white {
  color: #fff;
}

.background-default {
  background-color: #14007E
}

/* Page */
.page {
  padding: 40px 173px;
}

.page-padding {
  padding: 20px 173px;
}

/* Snapshot section */
.snapshot {
  overflow: hidden;
  border: 1px solid #979798;
  border-radius: 5px;
  color: #14007E;
  padding: 10px 30px;
  width: 100%;
  margin-top: 6%;
}

.snapshot-item {
  float: left;
  width: 25%;
}

.snapshot-row::after {
  display: table;
  clear: both;
}

.snapshot-label {
  font-weight: 400;
  font-size: 80%;
}

.snapshot-value {
  font-weight: 600;
  font-size: 160%;
  margin-top: 0;
}

/* Blocks */
.blocks-container {
  /* padding: 10px 30px; */

  margin-top: 4%;
}

.latest-blocks {
  overflow: auto;
  border: 1px solid #979798;
  border-radius: 5px;
  color: #14007E;
  padding: 10px 30px;
  width: 49%;
  float: left;
  height: 420px;
}

.transfers {
  overflow: auto;
  border: 1px solid #979798;
  border-radius: 5px;
  color: #14007E;
  padding: 10px 30px;
  width: 49%;
  float: right;
  height: 420px;
}

.block-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-left: 5px;
  margin-right: 5px;
}

.block-snapshot {
  border: 1px solid #979798;
  border-radius: 5px;
  color: #14007E;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4%;
  overflow: auto;
}

.block-snapshot-title {
  /* line-height: 72px; */
  font-weight: 600;
}

/* .block-snapshot-detail {
   line-height: 72px;
} */
.block-data {
  margin-top: 5rem;
}

.block-data-title {
  /* font-size: 20px; */
  /* line-height: 24px; */
  margin-right: 3rem;
}

.block-data-tabs {
  border-bottom: 1px solid #979798;
  padding-bottom: 20px;
}

.block-data-tab {
  display: inline-block;
  transform: translateY(20px);
  cursor: pointer;
}

.block-data-tab.active {
  color: #14007E;
  border-bottom: 1px solid #a6a3ff;
  padding-bottom: 20px;
}

.block-data-tab-details {
  border: 1px solid #979798;
  border-radius: 5px;
  color: #14007E;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4%;
  overflow: auto;
}

/* Extrinsics */
.extrinsics-filter-fields {
  border-bottom: 1px solid #575c60;
  padding-bottom: 10px;
  width: 220px;
  cursor: pointer;
}

.extrinsics-filter-fields input[type="text"],
.extrinsics-filter-fields input[type="number"] {
  width: 220px;
  font-size: 15px;
  /* background-color: #979797; */
  background-color: #fff !important;
  border: none;
  color: #979797;
  padding: 0 10px;
  border-radius: 3px;
}

.extrinsics-filter-fields input[type="date"] {
  background-color: #14007E;
  border: none;
  color: #fff;
  padding: 0 10px;
}

.filter-dropdown-content {
  position: absolute;
  background-color: #14007E;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 200px;
}

.filter-dropdown-content span {
  color: #fff;
  padding: 12px 16px;
  display: block;
  text-align: left;
  cursor: pointer;
}

.filter-dropdown-content span:hover {
  background-color: #979797;
  color: #14007E;
}

/* Validators */
.validators-container {
  /* padding: 10px 30px; */
  border: 1px solid #979798;
  border-radius: 5px;
  margin-top: 4%;
  margin-bottom: 20%;
  color: #14007E;
  padding: 10px 30px;
}

.validators-table {
  overflow-x: auto;
}

.blocks-list {
  overflow: hidden;
  border: 1px solid #979798;
  border-radius: 5px;
  color: #14007E;
  padding: 10px 30px;
  width: 100%;
  margin-top: 6%;
  height: 60vh;
  overflow: auto;
}

.block-list-search-container {
  background-color: #212427;
  width: 700px;
  height: 45px;
  border-radius: 5px;
}

.not-found-button {
  margin-top: 2em;
}

/* Table */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table thead th {
  text-align: left;
  padding: 16px 46px;
  font-size: 16px;
}

table tbody td {
  text-align: left;
  padding: 16px 46px;
  font-size: 16px;
}

table.bordered,
th.bordered,
td.bordered {
  border: 1px solid #979798;
}

.table-expandable-container {
  padding-right: 16px;
}

/* tr:nth-child(even) {
  background-color: #979798;
} */

th.no-wrap,
td.no-wrap {
  white-space: nowrap;
}

.expanded-view {
  padding: 30px;
  border-radius: 10px;
  background-color: #131416;
  color: #14007E;
}

/* Utilities */
/* content */
.bordered-content-box {
  border: 1px solid #979798;
  border-radius: 5px;
  color: #14007E;
  padding: 10px 30px;
}

/* Flex */
.d-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}

/* sizes */
.ml-3 {
  margin-left: 12px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-3 {
  margin-right: 12px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.above-footer {
  margin-bottom: 7rem;
}

.scroll-x {
  overflow-x: scroll;
}

.pagination {
  display: flex;
  list-style: none;
}

.pagination-item {
  color: #14007E;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.pagination-item.number {
  padding: 0 6px;
}

.pagination-item.number.active {
  background: rgba(166, 163, 255, 0.3);
  border-radius: 3px;
}

.pagination-item.caret {
  color: #979798;
}

.pagination-container li {
  list-style: none;
  background: rgba(166, 163, 255, 0.3);
  border-radius: 3px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
}

.pagination-container li a {}

.pagination-number-selected {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background: rgba(166, 163, 255, 0.3);
}

.pointer {
  cursor: pointer;
}

/* Message bar */
.message-bar {
  background-color: #212427;
  padding: 20px;
  color: #fff;
}

.message-bar .header {
  font-size: 1.2rem;
  font-weight: 700;
}

/* Footer */
.footer {
  padding: 20px 170px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #14007E;
  color: white;
}

.footer .nav-brand {
  width: 120px;
}

.footer .socials {
  float: right;
}

.footer .socials a {
  margin-left: 20px;
}

.footer .ptos {
  float: right;
}

/* Media queries */
@media screen and (max-width: 600px) {
  .badge {
    padding: 6px;
    margin-left: 0;
    font-size: 12px;
  }

  .block-data-title {
    font-size: 15px;
    /* line-height: 24px; */
    margin-right: 1rem;
  }

  .button.small {
    width: 100px;
    height: 35px;
    font-size: 80%;
  }

  .extrinsics-filter {
    display: none;
  }

  .topnav a,
  .nav-dropbtn {
    display: none;
  }

  .topnav input {
    width: 100%;
  }

  .topnav .nav-icon {
    float: right;
    display: block;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .nav-icon {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .topnav.responsive a,
  .topnav.responsive .nav-dropbtn {
    float: none;
    display: block;
    text-align: right;
    padding-top: 10px;
  }

  .topnav.responsive a:first-child {
    margin-top: 50px;
  }

  .topnav.topnav.responsive .dropdown-content a {
    margin-top: 0;
  }

  .chain-info {
    display: none;
  }

  .snapshot {
    padding: 5px 5px;
    text-align: center;
  }

  .snapshot-label {
    font-size: 60%;
  }

  .snapshot-value {
    font-size: 100%;
  }

  .transfers,
  .latest-blocks {
    width: 100%;
    padding: 5px 5px;
    overflow: auto;
    margin-bottom: 15px;
  }

  table thead th,
  table tbody td {
    padding: 10px;
    font-size: 80%;
  }

  .validators-container {
    padding: 5px 5px;
  }

  .tos {
    background-color: #212427;
  }
}